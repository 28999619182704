.regenagri-scorecard-comparison {
  h1, h2 {
    text-align: center;
  }

  .regenagri-scorecard-comparison-top-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .regenagri-scorecard-chart {
    text-align: center;
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .regenagri-scorecard-expand-button-container {
    text-align: center;
  }
}
