@import "variables.scss";

#regenagri-filter-panel {
  .regenagri-filter-panel-component {
    padding-bottom: 8px
  }
}

.regenagri-filters-panel {
  .regenagri-filter-panel-component {
    padding-bottom: 8px
  }
}

.regenagri-certified-assessment {
  background-color: $fourth-primary-color !important;

  .icon-badge {
    font-size: 0.8em;
  }

  svg {
    vertical-align: text-bottom;
    margin-right: 0.5em;
  }
}

.regenagri-group-admin-assessment {
  background-color: $fourth-primary-color !important;
}
