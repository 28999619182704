.regenagri-assessments-action-buttons {
  display: flex;
  justify-content: flex-end;

  * {
    margin-left: 12px;
    margin-top: 12px;
  }
}

.regenagri-assessments-checkbox,
.regenagri-assessments-completed,
.regenagri-assessments-action {
  text-align: center;
}
