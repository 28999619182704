@import 'variables.scss';

.regenagri-app {
  .regenagri-content {
    div[id*="error-list"] {
      display: none;
    }
  }

  .regenagri-top-buttons {
    width: 100%;
    text-align: left;
    margin-bottom: 16px;
  }

  .formio-form {
    width: 100%;

    .formio-component-fieldset.formio-hidden {
      display: none;
    }

    label, legend, .list-group-header, .card-title, .invalid-feedback {
      text-transform: lowercase;
    }

    // The up/down arrow on a dropdown
    .choices[data-type*="select-one"]::after {
      border-color: $text-color transparent transparent;
    }

    .choices[data-type*="select-one"].is-open::after {
      border-color: transparent transparent $text-color;
    }

    .is-highlighted {
      color: #fff;
      background-color: $third-primary-color;
    }

    .field-required::after {
      color: $second-accent-color;
    }

    .form-control:focus {
      border-color: $third-primary-color;
      box-shadow: 0 0 0 .2rem $field-glow-color;
    }

    .col-form-label {
      font-weight: bold;
      font-size: 1.1em;
    }

    div.formio-component-submit {
      margin-top: 16px;
    }

    .formio-component-fieldset {
      background-color: $pale-accent-color-darker;
      padding: 16px;

      .formio-component-fieldset {
        margin-bottom: 0px;
        padding-top: 0px;
        padding-bottom: 0px;
      }
    }

    .formio-component-certificationSchemes {
      background-color: transparent;
      padding: 16px 0 0 0;
    }

    .regenagri-preserve-case label {
      text-transform: none;
    }

    .editgrid-listgroup {
      .list-group-item {
        background-color: $pale-accent-color-darker;
        border: 0;
        border-radius: 0;
        border-bottom: 2px solid $fourth-neutral-color;

        .row {
          align-items: center;
        }
      }

      li:only-child {
        display: none;
      }
    }

    .formio-component-editgrid {
      .formio-component-plantings {
        border: 2px solid $fourth-neutral-color;
        padding: 8px 16px 16px 16px;
        background-color: $main-background-color;
      }
    }

    .formio-component-harvestedAmountUnits {
      .fa-question-circle:before {
        color: #dc3545 !important;
        content: "\f071";
      }
    }
  }
}
