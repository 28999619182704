@import 'variables.scss';

$verified-color: #009900;
$unverified-color: #ff0000;

$limited-color: $second-accent-color;
$full-member-color: #009900;

.regenagri-admin-dashboard {
  h1 {
    text-align: center;
  }

  h2 {
    margin-bottom: 16px;
  }

  .regenagri-top-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .dashboard-info {
    width: 100%;
    text-align: center;
  }

  .table {
    text-align: left;

    .verified, .unverified, .limited, .full-member {
      font-weight: bold;
    }

    .verified {
      color: $verified-color;
    }

    .unverified {
      color: $unverified-color;
    }

    .limited {
      color: $limited-color;
    }

    .full-member {
      color: $full-member-color;
    }

    span {
      display: inline-block;
      vertical-align: middle;
    }

    .regenagri-spinner svg {
      width: 100%;
      height: 48px;
      margin: -16px;
    }

    .upgrade-button {
      margin-left: 16px;
      font-size: 1em;
      padding: 4px;
    }
  }
}

.regenagri-admin-assessments-top-buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.create-certified-assessment-button {
  margin-top: 16px;
  margin-bottom: 32px;
}
