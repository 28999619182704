@import 'variables.scss';

.regenagri-scorecard {
  .regenagri-scorecard-top-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 24px;
  }

  .regenagri-scorecard-total {
    text-transform: lowercase;
    color: #fff;
    padding: 16px;
    font-size: 3em;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .regenagri-scorecard-total-score-values {
      text-align: right;

      .regenagri-scorecard-total-score-points {
        font-size: 0.4em;
      }
    }
  }

  .regenagri-scorecard-section {
    margin-top: 16px;
    padding: 16px;
    background-color: $pale-accent-color-darker;

    p + p {
      margin-bottom: 0;
      margin-top: 1em;
    }

    .regenagri-scorecard-section-header {
      display: flex;
      justify-content: space-between;

      .regenagri-scorecard-section-score {
        color: $darker-second-accent-color;
        margin-left: 40px;
        min-width: 120px;
      }
    }

    table, h4 {
      text-transform: lowercase;
    }

    table {
      width: 100%;
      margin-bottom: 0px;

      td + td, th + th {
        padding-left: 32px;
      }

      .regenagri-scorecard-component-field {
        text-align: right;
        width: 80%;
        text-transform: none;
      }

      .regenagri-scorecard-component-value {
        text-align: left;
        width: 20%;
      }
    }
    .regenagri-synergy-point-description {
      text-transform: lowercase;
    }
  }

  .regenagri-synergy-points-header {
    margin-top: 16px;
  }

  .regenagri-carbon-assessment-section {
    .regenagri-carbon-assessment-details {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-evenly;

      table {
        width: 95%;
      }
    }

    .regenagri-carbon-detail {
      width: 50%;
    }

    table {
      width: 100%;

      td + td, th + th {
        padding-left: 32px;
      }

      .regenagri-scorecard-component-field {
        text-align: right;
        width: 70%;
        text-transform: none;
      }

      .regenagri-scorecard-component-value {
        text-align: left;
        width: 30%;
      }
    }
  }

  .regenagri-scorecard-summary {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @media only screen and (max-width: 880px) {
      justify-content: center;
    }

    .regenagri-scorecard-summary-text {
      width: 45%;

      @media only screen and (max-width: 880px) {
        width: auto;
      }
    }

    .regenagri-scorecard-graph {
      svg {
        max-width: 70vw;
      }
    }

    .regenagri-scorecard-graph-text {
      font-size: 0.8em;
      padding: 16px;
      width: 350px;

      .regenagri-scorecard-your-score-text {
        color: $darker-second-accent-color;
      }

      .regenagri-scorecard-average-score-text {
        color: #101DAA;
      }
    }
  }

  .regenagri-ghg-warning {
    margin-top: 16px;
    padding: 16px;
    color: rgb(160, 4, 4);
  }

  .regenagri-scorecard-footer {
    font-size: 0.9em;
  }
}
