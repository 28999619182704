@import '../variables.scss';

.regenagri-add-farm-form, .regenagri-add-group-form, .regenagri-invite-user-form, .regenagri-add-user-form {
  .btn-primary {
    margin-left: 16px;
  }

  .form-control {
    margin-right: 16px;
  }

  padding: 16px 0;
}

.regenagri-assessments-action {
  text-align: center;
}

.regenagri-group-select-container {
  & > div {
    & > div {
      width: 100%;
    }
  }
}

.regenagri-add-user-form {
  margin-bottom: 24px;
}

.regenagri-invite-user-form {
  .regenagri-user-type-select {
    width: 160px;
  }
}

.regenagri-combined-area-input {
  text-transform: lowercase;

  .regenagri-area-unit-input {
    display: flex;
    flex-flow: column-reverse;
  }
}

.regenagri-combine-scorecard {
  .regenagri-farm-profile-details {
    h4 {
      font-style: italic;
    }
  }
}

h2.regenagri-overriden {
  font-size: 1rem !important;
}
