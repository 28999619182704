$top-secondary-color: #002060;
$second-secondary-color: #c2e0e6;

$dark-primary-color: #3b5650;
$second-primary-color: #7aa098;
$third-primary-color: #89b9af;
$fourth-primary-color: #91c6bc;
$fifth-primary-color: #e5f9f5;
$sixth-primary-color: #98a3a1;

$pale-accent-color: #fffcf3;
$pale-accent-color-darker: #eee;
$primary-accent-color: #e1e448;
$second-accent-color: #f7931e;
$darker-second-accent-color: #b96301;
$second-accent-color-highlight: #fcdfbf;
$third-accent-color: #ff99cc;
$third-accent-color-highlight: #fcc3df;

$darker-first-neutral-color: #222121;
$first-neutral-color: #3b3838;
$second-neutral-color: #767171;
$third-neutral-color: #afabab;
$fourth-neutral-color: #d0cece;

$field-glow-color: rgba(137, 187, 175, 0.5);
$text-color: $darker-first-neutral-color;
$main-background-color: #fafafa;

$mobile-screen-width-limit: 800px;

:export {
  textColor: $text-color;
  thirdAccentColor: $third-accent-color;
  thirdPrimaryColor: $third-primary-color;
  secondAccentColor: $second-accent-color;
}
