
.regenagri-admin-assessments-top-buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.create-assessment-button {
  margin-top: 16px;
  margin-bottom: 32px;
}
