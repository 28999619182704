.regenagri-spinner {
  margin: auto;
  width: 50%;

  svg {
    width: 100px;
    height: 100px;
    display: block;
    margin: 0 auto;
  }
}
