@import "variables.scss";

.main-wrapper {
  display: flex;
  justify-content: center;
  padding: 24px;
  padding-top: 152px;

  @media only screen and (max-width: $mobile-screen-width-limit) {
    padding-left: 16px;
    padding-right: 16px;
  }
}

.language-switch {
  display: block;
  margin-top: 17px;
  float: left;

  button {
    padding: 0;
    border: 0;
    background: none;
    outline: none;

    &.flag-uk img {
      height: 26px;
      width: 29px;
      margin-right: 3px;
    }

    &.flag-pt img {
      height: 23px;
      width: 30px;
    }
  }
}

#regenagri-nav-shade {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  min-height: 148px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 99;
  padding-left: 56px;
  padding-right: 48px;

  @media only screen and (max-width: $mobile-screen-width-limit) {
    padding-left: 24px;
    padding-right: 24px;
  }

  .regenagri-right-nav-container {
    a.btn.btn-primary {
      padding-top: 12px;
      padding-bottom: 12px;
      line-height: 35px;
      font-weight: 700;
      letter-spacing: 2px;
      min-width: 15vw;
      margin-left: 15px;
    }
  }
}

.regenagri-logo-small {
  width: 378px;
  max-width: 50vw;
  margin-right: 40px;
}
.bunge-logo-small {
  height: 75px;
  margin-bottom: 10px;
}

.regenagri-app,
.modal-dialog,
#regenagri-nav-shade {
  .btn {
    text-transform: lowercase;
    border-radius: 32px;
    padding-left: 32px;
    padding-right: 32px;
  }

  .btn-primary,
  .btn-default {
    color: #fff;
    background-color: $third-primary-color;
    border: 0;
    transition: 1s;

    @media only screen and (max-width: $mobile-screen-width-limit) {
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  .btn-group .btn {
    padding-left: 8px;
    padding-right: 8px;
  }

  .btn-group .btn:first-child {
    padding-left: 16px;
  }

  .btn-group .btn:not(:last-child) {
    border-right: 2px solid white;
  }

  .btn-group .btn:last-child {
    padding-right: 16px;
  }

  .btn-primary:hover,
  .btn-default:hover {
    background-color: $third-accent-color-highlight;
  }

  .btn-primary:active:focus,
  .btn-default:active:focus {
    background-color: $third-primary-color;
    box-shadow: 0 0 0 0 !important;
  }

  .btn-primary:focus,
  .btn-default:focus,
  .btn-primary:active,
  .btn-default:active {
    box-shadow: 0 0 0 0;
  }

  .btn-danger {
    background-color: $second-accent-color;
    border: 0;
  }
}

.regenagri-app {
  max-width: 100%;
  width: 874px;
  text-align: center;
  color: $text-color;

  h1,
  h2,
  h3,
  h4,
  h5,
  th {
    text-transform: lowercase;
  }

  h1 {
    @media only screen and (max-width: $mobile-screen-width-limit) {
      font-size: 1.8em;
    }
  }

  a {
    color: $second-accent-color;
  }

  a:hover {
    text-decoration: underline;
  }

  .btn:hover {
    text-decoration: none;
  }

  .divider {
    width: 100%;
    margin-top: 24px;
    margin-bottom: 24px;
    border-top: 1px solid $pale-accent-color-darker;
  }

  .alert-danger {
    color: $darker-second-accent-color;
    background-color: $second-accent-color-highlight;
    border-color: $second-accent-color;
  }

  .alert-info {
    color: $darker-first-neutral-color;
    background-color: $fourth-primary-color;
    border-color: $second-primary-color;
  }

  @media only screen and (max-width: $mobile-screen-width-limit) {
    box-shadow: none;
    border: 0;
  }

  .regenagri-logo {
    border: 0;
    margin: 24px;
    margin-top: 48px;
    max-width: calc(100% - 96px);

    @media only screen and (max-width: $mobile-screen-width-limit) {
      margin: 16px;
      max-width: calc(100vw - 48px);
    }
  }

  .regenagri-content {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 24px;
    text-align: left;

    .regenagri-top-buttons {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    @media only screen and (max-width: $mobile-screen-width-limit) {
      padding-left: 0px;
      padding-right: 0px;
    }

    > div {
      width: 100%;
    }

    .regenagri-login-page {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-evenly;

      .regenagri-login-page-text {
        max-width: 50%;
        padding: 16px;
        padding-top: 32px;

        @media only screen and (max-width: $mobile-screen-width-limit) {
          max-width: 400px;
        }
      }
    }

    .regenagri-small-form-box {
      width: 400px;
      padding: 1em;

      button[type="submit"] {
        width: 100%;
      }
      .back-button {
        margin-bottom: 20px;
        display: flex;
        justify-content: end;
      }
    }

    #regenagri-terms-and-conditions-box {
      margin-top: 64px;
    }
  }
}

.pale-text {
  color: #afabab;
}

.align-top {
  align-items: flex-start !important;
}