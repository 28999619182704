@import '../../variables.scss';

.filter-pill {
  border-radius: 2px;
  color: $text-color;
  font-size: 85%;
  padding: 3px;
  padding-left: 6px;
  white-space: nowrap;
  box-sizing: border-box;
  background-color: $fourth-neutral-color;
  margin: 2px;
  display: flex;
}
