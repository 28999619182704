@import './variables.scss';
@import url('https://fonts.googleapis.com/css2?family=Bitter&display=swap');
@import '~flatpickr/dist/flatpickr.min.css';

body {
  margin: 0;
  font-family: Bitter, serif,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $main-background-color;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
