@import '../../variables.scss';

.regenagri-table-pagination-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  .active {
    .page-link {
      background-color: $second-accent-color;
      border-color: #dee2e6;
      z-index: 0;
    }
  }
}
